import React from 'react'
import Icon from '../../../../components/icons/Icon'

type Props = {
    
    block1 : {
        title: string,
        text:string,
        icon:string,
        blocks: {
            text:string,
            icon?:string
        }[]
    },
    block2: {
        title:string,
        text:string
    }[],
    callToAction: {
        text:string,
        btnText:string
    }
}
export default function ProductTemplate({block1, block2, callToAction } : Props) {
  return (
    <div className="space-between-blocks max-w-[900px] mx-auto">
    <div className='flex space-x-16'>
        <div className='w-[400px] h-[400px] '>
                <Icon name={block1.icon} className='w-full h-full'/>
        </div>
        <div className="flex-1 space-y-4">
            <div>
                <p className='title-level2'>{block1.title}</p>
                <p className="text-level2">{block1.text}</p>

            </div>
            <div className='space-y-4'>
                {block1.blocks.map((block,i) => (
                    <div className="flex space-x-6" key={i}>
                        <div className="icon-sm mt-4">
                            <Icon className='w-full h-full' name='Check'/>
                        </div>
                        <p className='flex-1 text-level2'>{block.text}</p>
                    </div>

                ))}
                
            </div>
        </div>

    </div>
    <div className='space-y-4'>
        <p className="title-level2">Use cases</p>
        <div className="grid grid-cols-3 gap-x-7">
            {block2.map((block, i) => (
                <div key={i}>
                    <p className="text-level2 font-bold">{block.title}</p>
                    <p className="text-level2">
                        {block.text}
                    </p>
                </div>

            ))}
            
        </div>
    </div>
    <div className='flex space-x-7 items-center justify-end'>
        <p className=' text-level2'>{callToAction.text}</p>
        <button className='bg-skin-fill-inverted text-skin-inverted px-7 py-4 flex items-center rounded text-level2'>{callToAction.btnText}</button>
    </div>

</div>
  )
}
