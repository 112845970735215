import React from 'react'
import DetailsLayout from '../../../../components/details/layout'
import PageContainer from '../../../../components/details/layout/PageContainer'
import PageInner from '../../../../components/details/layout/PageInner'
import { useAppContext } from '../../../../context/appContext'
import { maxLgWinWidth } from '../../../../utils/utils'

import TextBlock2 from '../../../../components/details/blocks/block2/TextBlock2'
import Block1 from '../../../../components/details/blocks/block1'
import Icon from '../../../../components/icons/Icon'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'


export default function Products() {
  const { winSize } = useAppContext()
  const navigate = useNavigate()
  const location = useLocation()
  return (
    <div className="w-full h-full relative">
        <DetailsLayout navigateBack='/' hidden>
            <PageContainer isLg={winSize.w > maxLgWinWidth}>
                <PageInner>
                    <div className="  ">
                        <div className='flex items-center flex-col px-page pt-[40px]'>
                            <p className="label-level2 ">Produits</p>
                          
                            <h2 className='text-level1 text-center '>
                            Découvrez les types de volumes dans Qorddu File System
                            </h2>

                        </div>
                        <div className="flex justify-center border-b w-full pb-7 space-x-[70px] pt-[37px]" style={{borderColor: '#dedede'}}>
                                {/* <div className={`cursor-pointer w-max text-center ${location.pathname === '/home/products' && 'selected font-bold'}`} onClick={() => navigate('/home/products')}>
                                    <p className='text-level2 font-bold w-max '>Files</p>
                                </div>
                                <div className={`cursor-pointer w-max text-center ${location.pathname.includes('folder') && 'selected'} `} onClick={() => navigate('/home/products/folder')}>
                                    <p className='text-level2 font-bold w-max'>Folder</p>
                                </div>
                                <div className={`cursor-pointer w-max text-center ${location.pathname.includes('directory') && 'selected'} `} onClick={() => navigate('/home/products/directory')}>
                                    <p className='text-level2 font-bold w-max'>Directory</p>
                                </div> */}
                                <div className={`cursor-pointer w-max text-center ${location.pathname.includes('drive') && 'selected'} `} onClick={() => navigate('/home/products/drive')}>
                                    <p className='text-level2 w-max'>Qfs Drive</p>
                                </div>
                                <div className={`cursor-pointer w-max  text-center ${location.pathname.includes('server') && 'selected'}`} onClick={() => navigate('/home/products/server')}>
                                    <p className='text-level2 w-max'>Qfs Server</p>
                                </div>
                                <div className={`cursor-pointer w-max  text-center ${location.pathname.includes('cluster') && 'selected'}`} onClick={() => navigate('/home/products/cluster')}>
                                    <p className='text-level2 w-max'>Qfs Cluster</p>
                                </div>
                                <div className={`cursor-pointer w-max text-center  ${location.pathname.includes('domain') && 'selected'}`} onClick={() => navigate('/home/products/domain')}>
                                    <p className='text-level2 w-max'>Qfs Domain</p>
                                </div>

                        </div>
                        <div className='bg-white py-[100px] h-[calc(100vh-378px)] overflow-auto'>

                            <Outlet/>
                        </div>
                       
                      
                    </div>
            

                </PageInner>
            </PageContainer>

        </DetailsLayout>

    </div>

             
                      
                    
                    
      
  )
}
