import React, { useEffect, useRef, useState } from 'react'
import { renderHeightLg, renderHeightSm } from '../../../utils/utils'
import { useAppContext } from '../../../context/appContext'
import useGetnumberOfDocs from '../../../hooks/useGetnumberOfDocs'
import { useLocation } from 'react-router-dom'

type Props = {
    size: 'lg' | 'sm',
    children: JSX.Element,
    hidden?:boolean,
    scrollTo? : number
}
export default function OuterContainer({ size, children, hidden, scrollTo } : Props) {
    const { winSize } = useAppContext()
    const ref = useRef<any>()
    const innerRef = useRef<any>()
    const location = useLocation()
    useEffect(() => {
      
      ref?.current?.scrollTo({top: 0, behavior: 'smooth'})
    }, [location.pathname]);

    useEffect(() => {
      if(scrollTo){
        ref?.current?.scrollTo({top: scrollTo, behavior: 'smooth'})
      }
      
    }, [scrollTo]);

   
  return (
    <div className={`pt-7 pb-[50px] w-full px-4 flex justify-center ${hidden ? 'overflow-hidden' : 'overflow-auto'}`} style={{ height: size === 'sm' ? renderHeightSm(winSize.h) : renderHeightLg(winSize.h)}} ref={ref}>
        <div className='flex h-max w-full' ref={innerRef} onScroll={(e) => console.log(e)}>
         
            <div className="flex-1 flex ">
             
              <div className="flex-1 space-y-[30px]">
               {children}

              </div>
            </div>
        </div>
    </div>
  )
}
