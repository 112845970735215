import React, { useEffect, useRef, useState } from 'react'
import DetailsLayout from '../../../components/details/layout'
import PageContainer from '../../../components/details/layout/PageContainer'
import PageInner from '../../../components/details/layout/PageInner'
import { useAppContext } from '../../../context/appContext'
import { maxLgWinWidth } from '../../../utils/utils'

import TextBlock2 from '../../../components/details/blocks/block2/TextBlock2'

import Icon from '../../../components/icons/Icon'


export default function Solution() {
  const { winSize } = useAppContext()
  const ref1 = useRef<any>()
  const ref2 = useRef<any>()
  const ref3 = useRef<any>()
  const ref4 = useRef<any>()

  const [scrollTo, setscrollTo] = useState(undefined)

  const scrollToSection = (ref:any) => {
    setscrollTo(ref?.current?.offsetTop )
  }
  useEffect(() => {
    
    document.title = 'Teddra - Solutions'
  }, []);
  return (
    <div className="w-full h-full relative">
        <DetailsLayout navigateBack='/home' scrollTo={scrollTo}>
            <PageContainer isLg={winSize.w > maxLgWinWidth}>
                <PageInner>
                    <div className="">
                        <div className='space-y-7'>
                         
                            <div className='px-page h-[calc(100vh-192px)] min-h-[1100px] pt-page space-y-[90px]'>
                                <div className='text-center'>
                                    <p className="label-level2 ">Solutions</p>
                                    <p className='title-level1  w-full'>Presse, Leaders de communauté </p>
                                    <p className="text-level1">
                                    Devenez partenaire ou propriétaire d'un emplacement Qorddu File System
                                    </p>
                                </div>
                                <div className="grid grid-cols-4  gap-x-[30px] w-full justify-center ">
                                    <div className='text-center'>
                                        <Icon name='Annuiar' className='w-[60px] h-[60px] mx-auto'/>
                                        <p className="text-level2 font-bold">Editeurs d'annuaires</p>
                                        <p className="text-level2">
                                        Transformez votre annuaire en un centre de profit.
                                        </p>
                                        <button className='px-4 py-2 border rounded mt-7 block text-[15px] btn mx-auto' onClick={() => scrollToSection(ref1)}>
                                            En savoir plus
                                        </button>
                                    </div>
                                    <div className='text-center'>
                                        <Icon name='Community' className='w-[60px] h-[60px] mx-auto'/>
                                        <p className="text-level2 font-bold">Associations, chambres de commerce…</p>
                                        <p className="text-level2">
                                        Proposez un emplacement Qfs dédié aux acteurs et aux ressources de votre communauté.
                                        </p>
                                        <button className='px-4 py-2 border rounded mt-7 block text-[15px] btn mx-auto' onClick={() => scrollToSection(ref2)}>
                                            En savoir plus
                                        </button>
                                    </div>
                                    <div className='text-center'>
                                        <Icon name='Publisher' className='w-[60px] h-[60px] mx-auto'/>
                                        <p className="text-level2 font-bold">Presse spécialisée, presse régionale </p>
                                        <p className="text-level2">
                                        Faites de votre journal la porte d'entrée vers un secteur, un territoire...
                                        </p>
                                        <button className='px-4 py-2 border rounded mt-7 block text-[15px] btn mx-auto' onClick={() => scrollToSection(ref3)}>
                                            En savoir plus
                                        </button>
                                    </div>
                                    
                                    <div className='text-center'>
                                        <Icon name='Brand' className='w-[60px] h-[60px] mx-auto'/>
                                        <p className="text-level2 font-bold">Marques</p>
                                        <p className="text-level2">
                                        Fédérez, valorisez et analysez votre écosystème.
                                        </p>
                                        <button className='px-4 py-2 border rounded mt-7 block text-[15px] btn mx-auto' onClick={() => scrollToSection(ref4)}>
                                            En savoir plus
                                        </button>
                                    </div>

                                            
                                </div>

                            </div>
                           

                        </div>
                       
                        <div className="">
                        <div className="">
                                <div className='flex space-x-[55px] justify-between items-center px-page pb-block '>
                                    <div className=" left-0 w-[38%] h-auto">
                                        <Icon name='Annuiar' className='w-full h-dull'/>
                                    </div>
                                    <div className=" w-[50%]" ref={ref1}>
                                        <div className=''>
                                            <TextBlock2
                                                label="Editeurs d'annuaires"
                                                title="Transformez votre annuaire en un centre de profit"
                                                text={`Vous éditez un annuaire spécialisé, régional ou communautaire ? Ses coûts d'exploitation vous embarrassent ? Ses revenus sont inexistant ou limités ? Passez à Qorddu File System et transformez-le en un centre d'activités et de revenus à fort enjeu. `}
                                            />

                                        </div>
                                        <div className="mt-10 space-y-8">
                                            <div className='space-y-3'>
                                                <div className='space-y-8'>
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg'>
                                                            <Icon className='w-full h-full' name='Check'/>
                                                        </div>
                                                        <p className='text-level2 mt-0 flex-1'>"Ai Powered" (ajouts de mots clés, descriptif...).</p>
                                                    </div>
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg'>
                                                            <Icon className='w-full h-full' name='Check'/>
                                                        </div>
                                                        <p className='text-level2 mt-0 flex-1'>Système de publication avancé. </p>
                                                    </div>
                                                    <div className="flex space-x-3 ">
                                                        <div className='icon-lg'>
                                                            <Icon className='w-full h-full' name='Check'/>
                                                        </div>
                                                        <p className='text-level2 mt-0 flex-1'>Indexation des pages web des membres, du secteur d'activités...</p>
                                                    </div>
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg'>
                                                            <Icon className='w-full h-full' name='Check'/>
                                                        </div>
                                                        <p className='text-level2 mt-0 flex-1'>Recherche intuitive et pertinente.</p>
                                                    </div>
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg'>
                                                            <Icon className='w-full h-full' name='Check'/>
                                                        </div>
                                                        <p className='text-level2 mt-0 flex-1'>Richesse fonctionnelle (épingler, partager, appeler...).</p>
                                                    </div>
                                                    <div className="flex space-x-3 ">
                                                        <div className='icon-lg'>
                                                            <Icon className='w-full h-full' name='Check'/>
                                                        </div>
                                                        <p className='text-level2 mt-0 flex-1'>Mise en réseau avec d'autres éditeurs, d'autres emplacements.</p>
                                                    </div>
                                                
                                                    

                                                </div>                   
                                                    
                                            </div>  
                                           

                                        </div>

                                    </div>
                                    
                                </div>

                            </div>
                            <div className="">
                                <div className='space-y-4 px-page py-block'>
                                    <div className='flex justify-between items-center'>
                                        <div className="w-[50%]" ref={ref2}>
                                            <div className=''>
                                                <TextBlock2
                                                    label='Associations, chambres de commerce…'
                                                    title='Proposez un emplacement Qfs dédié aux acteurs et aux ressources de votre communauté'
                                                    text={`Associations sectorielles, chambres de commerce, technoparcs... : proposez votre emplacement Qfs pour valoriser les entreprises, les personnes et les ressources (produits, emplois...) de votre métier, territoire..., et augmenter vos revenus d'adhésion.`}
                                                />

                                            </div>
                                            <div className="mt-10 space-x-8 flex">
                                                <p className='text-level2 max-w-[193px] '>Les types de volumes accessibles aux communautés : </p>
                                                <div className=' flex-1'>
                                                    <div className='space-y-3'>
                                                        <div className="flex space-x-3 items-center">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full' name='Folders'/>
                                                            </div>
                                                            <p className='text-level2'>Folders</p>
                                                        </div>
                                                        <div className="flex space-x-3 items-center">
                                                                <div className='icon-lg  '>
                                                                    <Icon className='w-full h-full  ' name='Directory'/>
                                                                </div>
                                                                <p className='text-level2'>Directory</p>
                                                            </div>
                                                       
                                                        <div className="flex space-x-3 items-center">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full' name='Drive'/>
                                                            </div>
                                                            <p className='text-level2'>Drive</p>
                                                        </div>
                                                        
                                                    </div>
                                                  
                                                
                                                
                                                
                                                
                                                </div>

                                            </div>   
                                            

                                        </div>
                                        <div className=" w-[38%] h-auto">
                                            <Icon name='Community' className='w-full h-dull'/>
                                        </div>
                                    </div>
                                
                                </div>
                            </div>

                            <div className="">
                                <div className='flex space-x-[55px] justify-between items-center px-page py-block '>
                                    <div className=" left-0 w-[38%] h-auto">
                                        <Icon name='Publisher' className='w-full h-dull'/>
                                    </div>
                                    <div className=" w-[50%]" ref={ref3}>
                                        <div className=''>
                                            <TextBlock2
                                                label='Presse spécialisée, presse régionale '
                                                title="Faites de votre journal la porte d'entrée vers un secteur d'activités, une région..."
                                                text={`Presse spécialisée, presse régionale  : devenez partenaire exclusif d'un emplacement Qfs à l'échelle d'un grand secteur d'activités (Industrie France, Biotechs USA,...) ou d'une région (Québec, New York, Catalogne...), quadrillez le web et revenez au premier plan. Ensemble. `}
                                            />

                                        </div>
                                        <div className="mt-10 space-y-8">
                                            <p className='text-level2 '>Les volumes Qorddu File System pour les partenaires presse :</p>
                                            <div className='w-full'>
                                                
                                                <div className='space-y-3'>
                                                
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg  '>
                                                            <Icon className='w-full h-full  ' name='Server'/>
                                                        </div>
                                                        <p className='text-level2'>Thematic Servers</p>
                                                    </div>
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg  '>
                                                            <Icon className='w-full h-full  ' name='Server'/>
                                                        </div>
                                                        <p className='text-level2'>Regional Servers</p>
                                                    </div>
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg'>
                                                            <Icon className='w-full h-full' name='Cluster'/>
                                                        </div>
                                                        <p className='text-level2'>Cluster</p>
                                                    </div>

                                                </div>
                                                
                                            
                                            
                                            
                                            </div>

                                        </div>

                                    </div>
                                    
                                </div>

                            </div>

                     

                            <div className="">
                                <div className='space-y-4 px-page py-block'>
                                    <div className='flex justify-between items-center'>
                                        <div className="w-[50%]" ref={ref4}>
                                            <div className=''>
                                                <TextBlock2
                                                    label='Marques'
                                                    title='Fédérez, valorisez et analysez votre écosystème'
                                                    text={`Vous gérez une marque commerciale ou technologique au coeur d'un vaste écosystème de revendeurs, prestataires, prescripteurs, organismes de formation, etc. Offrez à votre écosystème un emplacement Qfs à l'échelle internationale ou mondiale et obtenez des données et des analyses d'un genre nouveau.`}
                                                />

                                            </div>
                                            <div className="mt-10 space-x-8 flex">
                                                <p className='text-level2 max-w-[193px] '>Les volumes Qorddu File System pour les marques :</p>
                                                <div className=' flex-1'>
                                                    <div className='space-y-3'>
                                                        <div className="flex space-x-3 items-center">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full' name='Server'/>
                                                            </div>
                                                            <p className='text-level2'>Servers</p>
                                                        </div>
                                                        <div className="flex space-x-3 items-center">
                                                                <div className='icon-lg  '>
                                                                    <Icon className='w-full h-full  ' name='Globe'/>
                                                                </div>
                                                                <p className='text-level2'>Domain</p>
                                                            </div>
                                                       
                                                       
                                                        
                                                    </div>
                                                  
                                                
                                                
                                                
                                                
                                                </div>

                                            </div> 
                                           
                                            

                                        </div>
                                        <div className=" w-[38%] h-auto">
                                            <Icon name='Brand' className='w-full h-dull'/>
                                        </div>
                                    </div>
                                
                                </div>
                            </div>

                          
                          
                        </div>
                    </div>
            

                </PageInner>
            </PageContainer>

        </DetailsLayout>

    </div>

             
                      
                    
                    
      
  )
}
