import React, { useEffect } from 'react'
import DetailsLayout from '../../../components/details/layout'
import PageContainer from '../../../components/details/layout/PageContainer'
import PageInner from '../../../components/details/layout/PageInner'
import { useAppContext } from '../../../context/appContext'
import { maxLgWinWidth } from '../../../utils/utils'


export default function Home() {
  const { winSize } = useAppContext()
  useEffect(() => {
    
    document.title = 'Teddra - Home'
  }, []);
  return (
    <div className="w-full h-full relative">
        <DetailsLayout navigateBack='/home'  hidden={winSize.h > 800}>
            <PageContainer isLg={winSize.w > maxLgWinWidth}>
                <PageInner>
                    <div className="space-between-main-blocks h-[calc(100vh-192px)] flex ">
                        <div className='space-y-7 min-h-[900px]'>
                            <div className="flex space-between-2cols h-full pl-page items-center">
                                <div className='w-[53%]'>
                                    <p className="label-level2 !text-[20px]">Presse, leaders de communautés</p>
                                    <p className="title-level1 text-[33px]">Ensemble. Plus forts. </p>
                                    <p className="text-level1 !text-[33px]">
                                    Rejoignez Qorddu File System, réservez votre moteur de recherche spécialisé, offrez une expérience Search inédite dans votre secteur, fédérez-vous en réseau et revenez au premier plan. Ensemble.
                                    </p>
                                </div>
                                <div className='flex-1 flex items-center justify-center  rounded-full border absolute top-0 right-[-30%] w-[900px] h-[900px]' style={{background: 'url("/images/home.png")', backgroundSize: 'cover', backgroundPosition: '10px 10px', backgroundRepeat: 'no-repeat'}}>

                                </div>
                            </div>
                      

                        </div>
                       
                      
                     
                    </div>
            

                </PageInner>
            </PageContainer>

        </DetailsLayout>

    </div>

             
                      
                    
                    
      
  )
}
