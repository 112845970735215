import React, { useEffect, useRef, useState } from 'react'
import DetailsLayout from '../../../components/details/layout'
import PageContainer from '../../../components/details/layout/PageContainer'
import PageInner from '../../../components/details/layout/PageInner'
import { useAppContext } from '../../../context/appContext'
import { maxLgWinWidth } from '../../../utils/utils'

import TextBlock2 from '../../../components/details/blocks/block2/TextBlock2'
import Icon from '../../../components/icons/Icon'


export default function Platform() {
  const { winSize } = useAppContext()
  const ref1 = useRef<any>()
  const ref2 = useRef<any>()
  const ref3 = useRef<any>()
  const [scrollTo, setscrollTo] = useState(undefined)

  const scrollToSection = (ref:any) => {
    setscrollTo(ref?.current?.offsetTop + 90)
  }

  useEffect(() => {
    
    document.title = 'Teddra - Plateforme'
  }, []);
  return (
    <div className="w-full h-full relative">
        <DetailsLayout navigateBack='/home' scrollTo={scrollTo}>
            <PageContainer isLg={winSize.w > maxLgWinWidth}>
                <PageInner>
                    <div className="space-between-main-blocks3">
                        <div className='space-y-7'>
                            <div className="flex space-between-2cols pt-page pl-page min-h-[calc(100vh-192px)]">
                                <div>
                                    <div className='  space-y-[90px]' >
                                        <div className='w-[calc(100%-546px)]'>
                                            <p className="label-level2 ">Plateforme</p>
                                            <p className='title-level1'>Qorddu file system,<br/> l'explorateur de fichiers du web</p>
                                            <p className="text-level1">
                                            Publier, ranger, rechercher, partager et exploiter l'information à l'échelle collective avec une précision, une pertinence et une productivité inconnues aujourd'hui.
                                            </p>
                                           

                                        </div>
                                    
                                        <div className="grid grid-cols-3 gap-x-[50px] w-[calc(100%-355px)]">
                                            <div>
                                                <Icon name='Naviguer' className='w-[60px] h-[60px]'/>
                                                <p className="text-level2 font-bold">Précision</p>
                                                <p className="text-level2">
                                                Naviguez à travers des milliers d'emplacements thématiques, géographiques et commerciaux.
                                                </p>
                                                <button className='px-4 py-2 border rounded mt-7 block text-[15px] btn ' onClick={() => scrollToSection(ref1)}>
                                                    En savoir plus
                                                </button>
                                            </div>
                                            <div>
                                                <Icon name='Search' className='w-[60px] h-[60px]'/>
                                                <p className="text-level2 font-bold">Pertinence</p>
                                                <p className="text-level2">
                                                Dans chacun de ces emplacements, recherchez des entreprises, des personnes, des salons, des formations...
                                                </p>
                                                <button className='px-4 py-2 border rounded mt-7 block text-[15px] btn' onClick={() => scrollToSection(ref2)}>
                                                    En savoir plus
                                                </button>
                                            </div>
                                            <div>
                                                <Icon name='Exploitez' className='w-[60px] h-[60px]'/>
                                                <p className="text-level2 font-bold">Productivité</p>
                                                <p className="text-level2">
                                                Exploitez les réponses immédiatement : appeler une entreprise, demander un badge pour un salon...
                                                </p>
                                                <button className='px-4 py-2 border rounded mt-7 block text-[15px] btn' onClick={() => scrollToSection(ref3)}>
                                                    En savoir plus
                                                </button>
                                            </div>

                                            
                                        </div>

                                    </div>
                                 
                                </div>
                                <div className='flex-1 flex items-center justify-center h-[513px]  rounded-l-[22px] absolute right-[30px] top-[84px]'>
                                    <Icon name='Disque' className='w-full h-full'/>
                                </div>
                            </div>
                           

                        </div>
                       
                      
                        <div className="mt-[180px]">
                            
                            <div className="relative" ref={ref1}>
                                <div className='flex space-x-[55px] justify-between items-center px-page py-block '>
                                    <div className=" w-[50%]">
                                        <div className=''>
                                            <TextBlock2
                                                label='Précision'
                                                title="Naviguez à travers des milliers d'emplacements spécialisés"
                                                text={`Avec Qorddu File System, toute personne ou organisation identifiée peut proposer un moteur de recherche spécialisé - appelé "Emplacement" - en rapport avec un secteur d'activités, une profession, un territoire, un écosystème, un catalogue, etc. `}
                                            />

                                        </div>
                                        <div className="mt-10 space-y-8">
                                            <p className='text-level2 '>Les types d'emplacements Qorddu File System :</p>
                                            <div className='grid grid-cols-3 gap-3'>
                                                <div className='space-y-3'>
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg'>
                                                            <Icon className='w-full h-full' name='Files'/>
                                                        </div>
                                                        <p className='text-level2'>Files</p>
                                                    </div>
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg'>
                                                            <Icon className='w-full h-full ' name='Folders'/>
                                                        </div>
                                                        <p className='text-level2'>Folders</p>
                                                    </div>
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg'>
                                                            <Icon className='w-full h-full' name='Directory'/>
                                                        </div>
                                                        <p className='text-level2'>Directories</p>
                                                    </div>

                                                </div>
                                                <div className='space-y-3'>
                                                <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg  '>
                                                            <Icon className='w-full h-full  ' name='Drive'/>
                                                        </div>
                                                        <p className='text-level2'>Drives</p>
                                                    </div>
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg  '>
                                                            <Icon className='w-full h-full  ' name='Server'/>
                                                        </div>
                                                        <p className='text-level2'>Servers</p>
                                                    </div>
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg'>
                                                            <Icon className='w-full h-full' name='Cluster'/>
                                                        </div>
                                                        <p className='text-level2'>Cluster</p>
                                                    </div>

                                                </div>
                                                <div className='space-y-3'>
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg '>
                                                            <Icon className='w-full h-full ' name='Datacenter'/>
                                                        </div>
                                                        <p className='text-level2'>Datacenters</p>
                                                    </div>
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg '>
                                                        </div>
                                                        <p className='text-level2'>....</p>
                                                    </div>
                                                
                                                    
                                                

                                                </div>
                                            
                                            
                                            
                                            </div>

                                        </div>

                                    </div>
                                    <div className='w-[900px] h-[900px] rounded-full border absolute right-[-449px] ' style={{background: 'url("/images/big-data.png") -439px -1522px / 505% no-repeat', boxShadow: '0 5px 31px rgb(0,0,0,0.1)'}}></div>
                                </div>

                            </div>
                            <div className='relative' ref={ref2}>
                                <div className='flex justify-end   items-center px-page py-block '>
                                    <div className='w-[900px] h-[900px] rounded-full border absolute left-[-395px]' style={{background: 'url("/images/big-data.png") -658px -1050px / 505% no-repeat', boxShadow: '0 5px 31px rgb(0,0,0,0.1)'}}></div>
                                    <div className=" w-[50%]">
                                        <div className="">
                                            <TextBlock2
                                                label='Pertinence'
                                                title='Recherchez parmi 100 types de publications'
                                                text={`Pages web, profils d'entreprises, profils de personnes, fiches produits, services, actualités, offres d'emplois, formations... Qorddu File System gère plus d'une centaine de types de publications pour offrir un "sens" aux réponses et une pertinence nouvelle.`}
                                            />

                                        </div>
                                        <div className="mt-10 space-y-8">
                                            <p className='text-level2 max-w-[463px]'>Quelques types de publications Qorddu File System (parmis plus de 100 types):</p>
                                            <div className='grid grid-cols-2 gap-7'>
                                                <div className='space-y-3'>
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg'>
                                                            <Icon className='w-full h-full' name='Webpage'/>
                                                        </div>
                                                        <p className='text-level2'>Pages web</p>
                                                    </div>
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg'>
                                                            <Icon className='w-full h-full' name='ProfilEntreprise'/>
                                                        </div>
                                                        <p className='text-level2'>Profils d'entreprises</p>
                                                    </div>
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg'>
                                                            <Icon className='w-full h-full ' name='ProfilPro'/>
                                                        </div>
                                                        <p className='text-level2'>Profils de personnes</p>
                                                    </div>
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg'>
                                                            <Icon className='w-full h-full' name='Products'/>
                                                        </div>
                                                        <p className='text-level2'>Produits</p>
                                                    </div>
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg'>
                                                            <Icon className='w-full h-full' name='Service'/>
                                                        </div>
                                                        <p className='text-level2'>Services</p>
                                                    </div>
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg'>
                                                            <Icon className='w-full h-full' name='JobOffer'/>
                                                        </div>
                                                        <p className='text-level2'>Offres d'emplois</p>
                                                    </div>

                                                </div>
                                                <div className='space-y-3'>
                                                <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg  '>
                                                            <Icon className='w-full h-full  ' name='News'/>
                                                        </div>
                                                        <p className='text-level2'>Actualités</p>
                                                    </div>
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg  '>
                                                            <Icon className='w-full h-full  ' name='Training'/>
                                                        </div>
                                                        <p className='text-level2'>Formations</p>
                                                    </div>
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg'>
                                                            <Icon className='w-full h-full' name='Events'/>
                                                        </div>
                                                        <p className='text-level2'>Salons</p>
                                                    </div>
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg'>
                                                            <Icon className='w-full h-full' name='MarketStudy'/>
                                                        </div>
                                                        <p className='text-level2'>Etudes de marché</p>
                                                    </div>
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg'>
                                                            <Icon className='w-full h-full' name='AppelsOffer'/>
                                                        </div>
                                                        <p className='text-level2'>Appels d'offres</p>
                                                    </div>
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg'>
                                                        </div>
                                                        <p className='text-level2'>...</p>
                                                    </div>

                                                </div>
                                            
                                            
                                            
                                            
                                            </div>

                                        </div>    
                                    </div>
                                </div>

                            </div>
                          
                            <div className="relative" ref={ref3}>
                                <div className='space-y-4 px-page py-block'>
                                    <div className='flex justify-between items-center'>
                                        <div className="w-[50%]">
                                            <div className=''>
                                                <TextBlock2
                                                    label='Productivité'
                                                    title='Exploitez les réponses immédiatement'
                                                    text={`Prévisualiser des réponses sans quitter la recherche, épingler des réponses, les partager, les importer dans un CRM ou un outil spécialisé... Qorddu File System va bien au-déla du clic pour proposer une richesse fonctionnelle et une fluidité inconnues dans l'exploitation des réponses.`}
                                                />

                                            </div>
                                            <div className="mt-10 space-y-8">
                                                <p className='text-level2 '>Quelques fonctionnalités proposées dans Qorddu File System :</p>
                                                <div className='grid grid-cols-2 gap-4 w-max'>
                                                    <div className='space-y-3'>
                                                        <div className="flex space-x-3 items-center">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full' name='Preview'/>
                                                            </div>
                                                            <p className='text-level2'>Visualiser</p>
                                                        </div>
                                                        <div className="flex space-x-3 items-center">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full ' name='Filter'/>
                                                            </div>
                                                            <p className='text-level2'>Filtrer</p>
                                                        </div>
                                                        <div className="flex space-x-3 items-center">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full' name='Pin'/>
                                                            </div>
                                                            <p className='text-level2'>Epingler</p>
                                                        </div>
                                                        <div className="flex space-x-3 items-center">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full ' name='Share'/>
                                                            </div>
                                                            <p className='text-level2'>Partager</p>
                                                        </div>
                                                    
                                                        <div className="flex space-x-3 items-center">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full ' name='Plus'/>
                                                            </div>
                                                            <p className='text-level2'>En savoir plus</p>
                                                        </div>
                                                    
                                                

                                                    </div>
                                                    <div className='space-y-3'>
                                                    <div className="flex space-x-3 items-center">
                                                            <div className='icon-lg  '>
                                                                <Icon className='w-full h-full  ' name='Phone'/>
                                                            </div>
                                                            <p className='text-level2'>Appeler</p>
                                                        </div>
                                                        <div className="flex space-x-3 items-center">
                                                            <div className='icon-lg  '>
                                                                <Icon className='w-full h-full  ' name='Chat'/>
                                                            </div>
                                                            <p className='text-level2'>Converser</p>
                                                        </div>
                                                        <div className="flex space-x-3 items-center">
                                                            <div className='icon-lg  '>
                                                                <Icon className='w-full h-full  ' name='Badge'/>
                                                            </div>
                                                            <p className='text-level2'>Demander un badge</p>
                                                        </div>
                                                        <div className="flex space-x-3 items-center">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full ' name='VisitCard'/>
                                                            </div>
                                                            <p className='text-level2'>Envoyer carte de visite</p>
                                                        </div>
                                                        <div className="flex space-x-3 items-center">
                                                            <div className='icon-lg'>
                                                            </div>
                                                            <p className='text-level2'>...</p>
                                                        </div>

                                                    </div>
                                                
                                                
                                                
                                                
                                                </div>

                                            </div>   
                                            

                                        </div>
                                        <div className='w-[900px] h-[900px] rounded-full border absolute right-[-449px]' style={{background: 'url("/images/big-data.png") -3151px -227px / 505% no-repeat', boxShadow: '0 5px 31px rgb(0,0,0,0.1)'}}></div>
                                    </div>
                                
                                </div>
                            </div>
                          
                        </div>
                    </div>
            

                </PageInner>
            </PageContainer>

        </DetailsLayout>

    </div>

             
                      
                    
                    
      
  )
}
