import React, { useEffect, useRef, useState } from 'react'
import DetailsLayout from '../../../components/details/layout'
import PageContainer from '../../../components/details/layout/PageContainer'
import PageInner from '../../../components/details/layout/PageInner'
import { useAppContext } from '../../../context/appContext'
import { maxLgWinWidth } from '../../../utils/utils'

import TextBlock2 from '../../../components/details/blocks/block2/TextBlock2'
import Icon from '../../../components/icons/Icon'


export default function Avantages() {
  const { winSize } = useAppContext()
  const ref1 = useRef<any>()
  const ref2 = useRef<any>()
  const ref3 = useRef<any>()
  const ref4 = useRef<any>()

  const [scrollTo, setscrollTo] = useState<any>(undefined)

  const scrollToSection = (ref:any) => {
    setscrollTo(ref?.current?.offsetTop - 20)
  }

  useEffect(() => {
    
    document.title = 'Teddra - Bénéfices'
  }, []);

  return (
    <div className="w-full h-full relative">
        <DetailsLayout navigateBack='/home' scrollTo={scrollTo}>
            <PageContainer isLg={winSize.w > maxLgWinWidth}>
                <PageInner>
                    <div className="space-between-main-blocks">
                        <div className='space-y-7'>
                            <div className="flex space-between-2cols px-page pt-page h-[calc(100vh-192px)] min-h-[1000px]">
                                <div>
                                    <div className='  space-y-[90px]' >
                                        <div className='max-w-[530px]'>
                                            <p className="label-level2 ">Bénéfices</p>
                                            <p className='title-level1'>Ramenez le business du search vers vous</p>
                                            <p className="text-level1">
                                            Augmentez votre trafic, votre visibilité, vos insights et vos revenus. Sans risques.
                                            </p>
                                           

                                        </div>
                                    
                                        <div className="grid grid-cols-4 gap-x-[30px] w-full">
                                            <div>
                                                <Icon name='GlobeSearch' className='w-[60px] h-[60px]'/>
                                                <p className="text-level2 font-bold">Trafic</p>
                                                <p className="text-level2">
                                                Augmentez votre activité web avec un service search unique sur le web.

                                                </p>
                                                <button className='px-4 py-2 border rounded mt-7 block text-[15px] btn' onClick={() => scrollToSection(ref1)}>
                                                    En savoir plus
                                                </button>
                                            </div>
                                            <div>
                                                <Icon name='GlobeNetwork' className='w-[60px] h-[60px]'/>
                                                <p className="text-level2 font-bold">Visibilité</p>
                                                <p className="text-level2">
                                                L'union fait la force, fédérez-vous en réseaux internationaux.
                                                </p>
                                                <button className='px-4 py-2 border rounded mt-7 block text-[15px] btn' onClick={() => scrollToSection(ref2)}>
                                                    En savoir plus
                                                </button>
                                            </div>
                                            <div>
                                                <Icon name='GlobeStat' className='w-[60px] h-[60px]'/>
                                                <p className="text-level2 font-bold">Analytiques</p>
                                                <p className="text-level2">
                                                Obtenez des stats précieuses sur votre secteur, territoire,... 
                                                </p>
                                                <button className='px-4 py-2 border rounded mt-7 block text-[15px] btn' onClick={() => scrollToSection(ref3)}>
                                                    En savoir plus
                                                </button>
                                            </div>
                                            <div>
                                                <Icon name='GlobMoney' className='w-[60px] h-[60px]'/>
                                                <p className="text-level2 font-bold">Revenus</p>
                                                <p className="text-level2">
                                                Recevez de 25 à 65%<br/> des revenus de votre emplacements.
                                                </p>
                                                <button className='px-4 py-2 border rounded mt-7 block text-[15px] btn' onClick={() => scrollToSection(ref4)}>
                                                    En savoir plus
                                                </button>
                                            </div>

                                            
                                        </div>

                                    </div>
                                 
                                </div>
                                {/* <div className='flex-1 flex items-center justify-center h-[513px]  rounded-l-[22px] absolute right-[30px] top-0'>
                                    <Icon name='Disque' className='w-full h-full'/>

                                </div> */}
                            </div>
                           

                        </div>
                       
                      
                        <div className="">
                            <div className=" px-page pb-block">
                                <div className='flex space-x-[90px] justify-between  items-center  ' ref={ref1}>
                                    <div className=" w-[50%]">
                                        <div className=''>
                                            <TextBlock2
                                                label='Trafic'
                                                title="Augmentez votre activité web avec un service search unique sur le web"
                                                text={`Positionnez votre organisation au coeur d'un secteur d'activités, d'un territoire ou d'une grande communauté en proposant un outil de recherche dédié, une pertinence nouvelle et une richesse fonctionnelle inconnues aujourd'hui. `}
                                               
                                            />
                                            <div className="mt-10 space-y-8">
                                                <div className='space-y-3'>
                                                    <div className='space-y-8'>
                                                        <div className="flex space-x-3">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full' name='Check'/>
                                                            </div>
                                                            <p className='text-level2 mt-0 flex-1'>Votre emplacement Qfs est un véritable moteur de recherche spécialisé.
                                                            </p>
                                                        </div>
                                                        <div className="flex space-x-3">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full' name='Check'/>
                                                            </div>
                                                            <p className='text-level2 mt-0 flex-1'>
                                                            Offrez une pertinence sans équivalent avec une centaine de types de publications (entreprises, personnes...).
 
                                                            </p>
                                                        </div>
                                                        <div className="flex space-x-3">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full' name='Check'/>
                                                            </div>
                                                            <p className='text-level2 mt-0 flex-1'>
                                                            Votre widget Qfs offre une richesse fonctionnelle inédite dans l'univers du search.
 
 
                                                            </p>
                                                        </div>
                                                 
                                                    
                                                    
                                                        

                                                    </div>                   
                                                        
                                                </div>  
                                            

                                            </div>

                                        </div>
                                        

                                    </div>
                                    {/* <div className='w-[900px] h-[900px] rounded-full border absolute right-[-449px] ' style={{background: 'url("/images/big-data.png") -439px -1522px / 505% no-repeat', boxShadow: '0 5px 31px rgb(0,0,0,0.1)'}}></div> */}
                                    <div className=" w-[38%] h-auto">
                                        <Icon name='GlobeSearch' className='w-full h-dull'/>
                                    </div>
                                </div>
                               

                            </div>
                            <div className=' px-page py-block'>
                                <div className='flex items-center space-x-[90px] justify-between ' ref={ref2}>
                                    <div className=" left-0 w-[38%] h-auto">
                                        <Icon name='GlobeNetwork' className='w-full h-dull'/>
                                    </div>
                                    <div className=" w-[50%]">
                                        <div className="">
                                            <TextBlock2
                                                label='Visibilité'
                                                title="L'union fait la force, fédérez-vous en réseaux internationaux"
                                                text={`L'avenir est dans le réseau : faites partie d'un vaste réseau éditorial, augmentez votre visibilité à l'échelle internationale en fédérant vos emplacements respectifs en un puissant système d'indexation et de recherche décentralisé.`}
                                            />

                                        </div>
                                        <div className="mt-10 space-y-8">
                                                <div className='space-y-3'>
                                                    <div className='space-y-8'>
                                                        <div className="flex space-x-3">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full' name='Check'/>
                                                            </div>
                                                            <p className='text-level2 mt-0 flex-1'>
                                                            Mettez en réseau vos marques, vos audiences et vos emplacements Qfs.
 
                                                            </p>
                                                        </div>
                                                        <div className="flex space-x-3">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full' name='Check'/>
                                                            </div>
                                                            <p className='text-level2 mt-0 flex-1'>
                                                            Participez au déploiement d'un puissant système de recherche décentralisé et créez ensemble des portes d'entrée thématiques (industrie, santé, économies régionales, etc) à l'échelle du web.
 
 
                                                            </p>
                                                        </div>

                                                    </div>                   
                                                        
                                                </div>  
                                            

                                            </div>
                                    </div>
                                </div>
                             

                            </div>

                            <div className=' px-page py-block'>
                                <div className='flex justify-between items-center space-x-[90px]  ' ref={ref3}>
                                 
                                    <div className=" w-[50%]">
                                        <div className="">
                                            <TextBlock2
                                                label='Analytiques'
                                                title=" Obtenez des stats précieuses sur votre secteur, territoire,...."
                                                text={`Votre emplacement Qfs génère du trafic et de l'activité (recherche, ajouts de publications...). Accédez aux rapports statistiques et tirez des enseignements précieux sur le fonctionnement de votre secteur d'activité, de votre territoire...`}
                                            />

                                        </div>
                                    </div>
                                    <div className=" left-0 w-[38%] h-auto">
                                        <Icon name='GlobeStat' className='w-full h-dull'/>
                                    </div>
                                </div>
                           

                            </div>
                          
                            <div className=" px-page py-block">
                                <div className='space-y-4' ref={ref4}>
                                    <div className='flex justify-between  space-x-[90px] items-center'>
                                        <div className="w-[38%] h-auto">
                                            <Icon name='GlobMoney' className='w-full h-dull'/>
                                        </div>
                                        <div className="w-[50%]">
                                            <div className=''>
                                                <TextBlock2
                                                    label='Revenus'
                                                    title='Recevez de 25 à 65% des revenus de votre emplacements.'
                                                    text={`Invitez les entreprises et les professionnels de votre secteur, de votre territoire ou de votre communauté à s'abonner à votre emplacement, à y publier et promouvoir leurs messages et offres (produits, services, emplois...). Vous recevrez jusqu'à 60% des revenus générés par votre emplacement.`}
                                                />

                                            </div>
                                            

                                        </div>
                                     
                                      
                                    </div>
                             
                                
                                </div>
                            </div>
                          
                        </div>
                    </div>
            

                </PageInner>
            </PageContainer>

        </DetailsLayout>

    </div>

             
                      
                    
                    
      
  )
}
