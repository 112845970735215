import React from 'react'
import { Outlet } from 'react-router-dom'
import Resources from '.'
import useSetBreadcrumb from '../../hooks/useSetBreadcrumb'
import { useAppContext } from '../../context/appContext'
import { maxLgWinWidth, renderHeightLg } from '../../utils/utils'

type Props = {
  data:{title:string, key:string,blocks:{key:string, name:string,description:string, icon:string, disabled?:boolean}[]}[],
  children:JSX.Element,
  parent?: {
    key:string,
    name:string
  }
  
}
export default function ResourcesLocationLayout({children, data, parent}: Props) {
  useSetBreadcrumb({data, parent})
  const { winSize } = useAppContext()
  
  return (
    <div className="flex flex-1 h-full">
        <div className={`${winSize.w > maxLgWinWidth ? 'w-[30%] max-[1825px]:w-[400px] max-[1660px]:w-[300px]' : 'w-full'} pl-10 pt-layout overflow-auto`} style={{height : renderHeightLg(winSize.h)}}>
            <Resources data={data}/>
        </div>
        <div className={` ${winSize.w > maxLgWinWidth && 'flex-1'} h-full`}>
            <Outlet/>
            
        </div>      

    </div>
  )
}
