import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const LanguageSwitcher = () => {
    const { i18n } = useTranslation();
    const navigate = useNavigate();
  
    const changeLanguage = (lng:string) => {
      i18n.changeLanguage(lng);
      const currentPath = window.location.pathname.split('/').slice(2).join('/');
      navigate(`/${lng}/${currentPath}`, { replace: true });
    };

  return (
    <div className='flex items-center space-x-2'>
      <button onClick={() => changeLanguage('en')}>English</button>
      <button onClick={() => changeLanguage('fr')}>Français</button>
    </div>
  );
};

export default LanguageSwitcher;
