import React, { useEffect, useRef, useState } from 'react'
import DetailsLayout from '../../../components/details/layout'
import PageContainer from '../../../components/details/layout/PageContainer'
import PageInner from '../../../components/details/layout/PageInner'
import { useAppContext } from '../../../context/appContext'
import { maxLgWinWidth } from '../../../utils/utils'

import TextBlock2 from '../../../components/details/blocks/block2/TextBlock2'
import Icon from '../../../components/icons/Icon'


export default function HowItWorks() {
  const { winSize } = useAppContext()
  const ref1 = useRef<any>()
  const ref2 = useRef<any>()
  const ref3 = useRef<any>()
  const ref4 = useRef<any>()
  const [scrollTo, setscrollTo] = useState<any>(undefined)

  const scrollToSection = (ref:any) => {
    console.log(ref?.current?.offsetTop)
    setscrollTo(ref?.current?.offsetTop - 20)
  }

  useEffect(() => {
    
    document.title = 'Teddra - Comment ça marche'
  }, []);
  return (
    <div className="w-full h-full relative">
        <DetailsLayout navigateBack='/home' scrollTo={scrollTo}>
            <PageContainer isLg={winSize.w > maxLgWinWidth}>
                <PageInner>
                    <div className="space-between-main-blocks">
                        <div className='space-y-7'>
                            <div className="flex space-between-2cols  px-page h-[calc(100vh-192px)] min-h-[900px] pt-page">
                                <div>
                                    <div className='  space-y-[90px]' >
                                        <div className='max-w-[530px]'>
                                            <p className="label-level2 ">Comment ça marche ?</p>
                                            <p className='title-level1'>Votre emplacement clés en main</p>
                                            <p className="text-level1">
                                            N'immobilisez aucune ressource interne, votre emplacement Qfs est entièrement géré pour vous.
                                            </p>
                                           

                                        </div>
                                    
                                        <div className="grid grid-cols-4 gap-x-[50px] ">
                                            <div>
                                                <Icon name='Platform' className='w-[60px] h-[60px]'/>
                                                <p className="text-level2 font-bold">Plateforme</p>
                                                <p className="text-level2">
                                                Tout se passe dans le cloud.
                                                </p>
                                                <button className='px-4 py-2 border rounded mt-7 block text-[15px] btn' onClick={() => scrollToSection(ref1)}>
                                                    En savoir plus
                                                </button>
                                            </div>
                                            <div>
                                                <Icon name='Access' className='w-[60px] h-[60px]'/>
                                                <p className="text-level2 font-bold">Accés</p>
                                                <p className="text-level2">
                                                Un widget facile à intégrer dans votre site.
                                                </p>
                                                <button className='px-4 py-2 border rounded mt-7 block text-[15px] btn' onClick={() => scrollToSection(ref2)}>
                                                    En savoir plus
                                                </button>
                                            </div>
                                            <div>
                                                <Icon name='Contenu' className='w-[60px] h-[60px]'/>
                                                <p className="text-level2 font-bold">Contenu</p>
                                                <p className="text-level2">
                                                Tout est géré en arrière plan.
                                                </p>
                                                <button className='px-4 py-2 border rounded mt-7 block text-[15px] btn' onClick={() => scrollToSection(ref3)}>
                                                    En savoir plus
                                                </button>
                                            </div>
                                            <div>
                                                <Icon name='Promotion' className='w-[60px] h-[60px]'/>
                                                <p className="text-level2 font-bold">Promotion</p>
                                                <p className="text-level2">
                                                Effet réseau et LinkedIn pour une visibilité maximale.
                                                </p>
                                                <button className='px-4 py-2 border rounded mt-7 block text-[15px] btn' onClick={() => scrollToSection(ref4)}>
                                                    En savoir plus
                                                </button>
                                            </div>

                                            
                                        </div>

                                    </div>
                                 
                                </div>
                                {/* <div className='flex-1 flex items-center justify-center h-[513px]  rounded-l-[22px] absolute right-[30px] top-0'>
                                    <Icon name='Disque' className='w-full h-full'/>

                                </div> */}
                            </div>
                           

                        </div>
                       
                      
                        <div className="">
                            <div className=" ">
                                <div className="px-page pb-block">
                                    <div className='flex space-x-[55px] justify-between items-center  ' ref={ref1}>
                                        <div className=" w-[50%]">
                                            <div className=''>
                                                <TextBlock2
                                                    label='Plateforme'
                                                    title="Tout se passe dans le cloud"
                                                    text={`Votre emplacement Qfs fonctionne comme un service web. Vous n'immobilisez aucune ressource interne. Les évolutions de la plateforme, la gestion du contenu, des abonnements, des facturations, etc : tout est géré dans le cloud. `}
                                                
                                                />

                                            </div>
                                            <div className="mt-10 space-y-8">
                                                <div className='space-y-3'>
                                                    <div className='space-y-8'>
                                                        <div className="flex space-x-3 items-center">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full' name='Check'/>
                                                            </div>
                                                            <p className='text-level2 mt-0 flex-1'>Un service web basé sur Google Cloud Platform. </p>
                                                        </div>
                                                        <div className="flex space-x-3 items-center">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full' name='Check'/>
                                                            </div>
                                                            <p className='text-level2 mt-0 flex-1'>Une infrastructure robuste et sécurisée.</p>
                                                        </div>
                                                        <div className="flex space-x-3 ">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full' name='Check'/>
                                                            </div>
                                                            <p className='text-level2 mt-0 flex-1'>Une roadmap d'innovations majeures (accès à la feuille de route sous conditions). </p>
                                                        </div>
                                                        

                                                    </div>
                                                    
                                                
                                                
                                                
                                                </div>

                                            </div>
                                            

                                        </div>
                                        {/* <div className='w-[900px] h-[900px] rounded-full border absolute right-[-449px] ' style={{background: 'url("/images/big-data.png") -439px -1522px / 505% no-repeat', boxShadow: '0 5px 31px rgb(0,0,0,0.1)'}}></div> */}
                                    
                                        <div className=" w-[38%] h-auto">
                                            <Icon name='Platform' className='w-full h-dull'/>
                                        </div>
                                    </div>
                                   

                                </div>

                            </div>
                            <div className=" ">
                                <div className=' px-page py-block'>
                                    <div className='flex justify-between items-center space-x-[55px]  ' ref={ref2}>
                                    
                                        <div className=" left-0 w-[38%] h-auto">
                                            <Icon name='Access' className='w-full h-dull'/>
                                        </div>
                                        <div className=" w-[50%]">
                                            <div className="">
                                                <TextBlock2
                                                    label='Accés'
                                                    title="Un widget facile à intégrer dans votre site"
                                                    text={`Intégrez le widget Qfs dans votre site en quelques secondes et offrez un accès rapide, intuitif et fluide à votre emplacement mais aussi à tous les emplacements Qfs. `}
                                                />

                                            </div>
                                            <div className="mt-10 space-y-8 ">
                                                <div className='space-y-3'>
                                                    <div className='space-y-8'>
                                                        <div className="flex space-x-3 items-center">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full' name='Check'/>
                                                            </div>
                                                            <p className='text-level2 mt-0 flex-1'>5 lignes de codes à ajouter à votre page d'accueil. </p>
                                                        </div>
                                                        <div className="flex space-x-3 items-center">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full' name='Check'/>
                                                            </div>
                                                            <p className='text-level2 mt-0 flex-1'>Aide à l'intégration du widget.</p>
                                                        </div>
                                                    
                                                        <div className="flex space-x-3 items-center">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full' name='Check'/>
                                                            </div>
                                                            <p className='text-level2 mt-0 flex-1'>Un widget discret et léger au chargement.</p>
                                                        </div>
                                                    
                                                        <div className="flex space-x-3 items-center">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full' name='Check'/>
                                                            </div>
                                                            <p className='text-level2 mt-0 flex-1'>Un accès intuitif et fluide à votre emplacement et à tout les emplacements Qfs. </p>
                                                        </div>
                                                    
                                                    
                                                        

                                                    </div>
                                                    
                                                
                                                
                                                
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                   

                                </div>

                            </div>
                            <div className="">
                                <div className=" px-page py-block">
                                    <div className='flex space-x-[55px] justify-between items-center' ref={ref3}>
                                        <div className="w-[50%]">
                                            <div className=''>
                                                <TextBlock2
                                                    label='Contenu'
                                                    title='Toute est géré en arrière plan'
                                                    text={`Nos équipes et nos algorithmes amorcent et gèrent pour vous un index riche et à jour des acteurs et des ressources (entreprises, personnes, salons, formations,...) dans votre spécialité ou votre territoire.`}
                                                />

                                            </div>
                                            <div className="mt-10 space-y-8">
                                                <div className='space-y-3'>
                                                    <div className='space-y-8'>
                                                        <div className="flex space-x-3 ">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full' name='Check'/>
                                                            </div>
                                                            <p className='text-level2 mt-0 flex-1'>Gestion du référentiel des entreprises de votre emplacement.</p>
                                                        </div>
                                                        <div className="flex space-x-3 items-center">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full' name='Check'/>
                                                            </div>
                                                            <p className='text-level2 mt-0 flex-1'>Indexation des pages web basée sur l'IA.</p>
                                                        </div>
                                                        <div className="flex space-x-3 ">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full' name='Check'/>
                                                            </div>
                                                            <p className='text-level2 mt-0 flex-1'> Equipe dédiée pour les gros emplacements sectoriels et géographiques.</p>
                                                        </div>
                                                        

                                                    </div>
                                                    
                                                
                                                
                                                
                                                </div>

                                            </div>
                                            

                                        </div>
                                        <div className=" w-[38%] h-auto">
                                            <Icon name='Contenu' className='w-full h-dull'/>
                                        </div>
                                    </div>
                                        

                                    
                                </div>

                            </div>
                            
                            <div className="">
                                <div className='px-page py-block '>
                                    <div className='flex justify-between items-center space-x-[55px]  ' ref={ref4}>
                                        <div className=" left-0 w-[38%] h-auto">
                                            <Icon name='Promotion' className='w-full h-dull'/>
                                        </div>
                                        <div className=" w-[50%]">
                                            <div className="">
                                                <TextBlock2
                                                    label='Promotion'
                                                    title="Effet réseau et LinkedIn pour une visibilité maximale"
                                                    text={`Un index riche et une interface moderne ne suffisent pas à assurer le succès. Nos équipes marketing travaillent à rendre visible Qorddu File System et ses milliers d'emplacements dans les réseaux sociaux et en particulier LinkedIn.`}
                                                />

                                            </div>
                                            <div className="mt-10 space-y-8">
                                                <div className='space-y-3'>
                                                    <div className='space-y-8'>
                                                        <div className="flex space-x-3">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full' name='Check'/>
                                                            </div>
                                                            <p className='text-level2 mt-0 flex-1'>Effet réseau : chaque emplacement Qfs est un point d'accès et de promotion vers tous les autres emplacements.</p>
                                                        </div>
                                                        <div className="flex space-x-3">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full' name='Check'/>
                                                            </div>
                                                            <p className='text-level2 mt-0 flex-1'>Intégration poussée avec LinkedIn : page dédiée à chaque verticale, gestion des posts, annonces des nouveaux emplacements...</p>
                                                        </div>
                                                    </div>
                                                    
                                                
                                                
                                                
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    
                                </div>

                            </div>


                          
                        </div>
                    </div>
            

                </PageInner>
            </PageContainer>

        </DetailsLayout>

    </div>

             
                      
                    
                    
      
  )
}
