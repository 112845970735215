import React from 'react'
import Icon from '../../icons/Icon'

type Props ={
    main:boolean,
    close:() => void
}
export default function TopbarIcons({ main, close } : Props) {
  return (
    <div className='flex items-center  '>
        {main && (
            <>
            
                <div className="flex items-center space-x-2">
                    <button className='w-[24px] h-[24px] rounded bg-hover-transparent no-dragable flex items-center justify-center disabled:opacity-[0.2]' disabled>
                        <Icon name='Info' className='icon-sm'/>
                    </button>
                </div>
                <div className='w-[1px] h-[15px] mx-4 bg-white opacity-[0.2]'></div>
            </>
        )}
        
        <div className="flex items-center space-x-2">
            {main && (
                <>
                    <button className='w-[24px] h-[24px] rounded bg-hover-transparent no-dragable flex items-center justify-center disabled:opacity-[0.2]' disabled>
                        <Icon name='Reduce' className='icon-sm'/>
                    </button>
                    <button className='w-[24px] h-[24px] rounded bg-hover-transparent no-dragable flex items-center justify-center disabled:opacity-[0.2]' disabled>
                        <Icon name='Maximize' className='icon-sm'/>
                    </button>
                
                </>
            )}
            <button className='cursor-pointer no-dragable hover:bg-teddra-red hover:text-white hover:fill-white w-[24px] h-[24px] border-transparent flex justify-center items-center rounded disabled:opacity-[0.2]' disabled={main} onClick={close}>
                <Icon name='Close' className='icon-sm ' />
            </button>

        

        </div>
        

    </div>
  )
}
