import React from 'react'
import ProductTemplate from './template'

export default function Server() {
  return (
    <ProductTemplate
        block1={
            {
                title: 'Lorem ipsum',
                icon: 'Server',
                text:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius ducimus cumque iusto ",
                blocks: [
                    {
                        text: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquid asperiores id"
                    },
                    {
                        text: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquid asperiores id"
                    },
                    {
                        text: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquid asperiores id"
                    },
                ]
            }
        }
        block2={[
            {
                title: "Lorem ipsum dolor",
                text: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi odio eos eveniet laudantium optio  amet consectetur adipisicing elit. Quasi odio eos eveniet laudantium optio"
            },
            {
                title: "Lorem ipsum dolor",
                text: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi odio eos eveniet laudantium optio "
            },
            {
                title: "Lorem ipsum dolor",
                text: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi odio eos eveniet laudantium optio"
            }
        ]}
        callToAction={
            {
                btnText: "Je veux un Server",
                text: "Lorem ipsum dolor sit amet consectetu"
            }
        }
    
    />
  )
}
